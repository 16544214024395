import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Handlebars from "handlebars";
import QuoteTitleHeader from "../../components/quote/quoteTitleHeader";
import {
    HOGAR,
    AUTOS,
    VIDA,
    SALUD,
    GASTOS_MEDICOS,
} from "../../utils/risksTypes";
import PropTypes from "prop-types";
import { getInsurance } from "../../actions/insuranceActions";
import MessageFailure from "../../components/messages/messageFailure";
import { useQuote } from "../../hooks/useQuote";
import { patchPlan, recoverQuotation } from "../../actions/quoteActions";
import { saveClientRecognitionInfo } from "../../actions/clientInfoActions";

const QuotationContainer = ({
    children,
    riskType,
    insuranceId,
    location,
    showHeader,
    handleSetCustomHeader,
    formBuilderId,
    id,
}) => {
    riskType = riskType && riskType.toUpperCase();
    const dispatch = useDispatch();
    const insurances = useSelector((state) => state.insurance.data);
    const [insurance, setInsurance] = useState();
    const quote = useQuote(riskType);
    const { quotation } = quote;

    if (!localStorage.getItem("quotation::risktype")) {
        localStorage.setItem("quotation::risktype", riskType);
    }
    if (!localStorage.getItem("quotation::formBuilderId")) {
        localStorage.setItem("quotation::formBuilderId", formBuilderId);
    }
    if (!localStorage.getItem("quotation::idf")) {
        localStorage.setItem("quotation::idf", id);
    }
    if (!localStorage.getItem("quotation::resultQuoteId")) {
        localStorage.setItem("quotation::resultQuoteId", insuranceId);
    }

    let savedQuotation = sessionStorage.getItem(
        `quotation::quote::${riskType}`
    );

    useEffect(() => {
        initInsurance();
        if (location.pathname.includes('embed')) {
            Object.keys(sessionStorage)
                .filter(quote => quote.includes('quotation'))
                .forEach(quote => {
                    sessionStorage.removeItem(quote);
                });

            Object.keys(localStorage)
                .filter(quote => quote.includes('quotation'))
                .forEach(quote => {
                    localStorage.removeItem(quote);
                });

            Object.keys(localStorage)
                .filter(quote => quote.includes('cotizador'))
                .forEach(quote => {
                    localStorage.removeItem(quote);
                });
        } else {
            initQuotation();
            initScanInfo();
        }
    }, []);

    useEffect(() => {
        const insuranceResult = insurances.find(
            (insuranceFind) => insuranceFind._id === insuranceId
        );
        if (insuranceResult) {
            if (insuranceResult.branding) {
                const template = Handlebars.compile(
                    insuranceResult.branding.template
                );
                handleSetCustomHeader(template());
            }
        }
        setInsurance(insuranceResult);
    }, [insurances]);

    const initInsurance = () => {
        if (!insurances.length) {
            dispatch(getInsurance());
        }
    };

    const initQuotation = () => {
        if (Object.keys(quotation).length === 0 && savedQuotation) {
            console.log("Quotation needs to be recovered");
            savedQuotation = JSON.parse(savedQuotation);

            if (savedQuotation.quotation) {
                dispatch(recoverQuotation(savedQuotation.quotation));
            }
            if (savedQuotation.plan) {
                dispatch(patchPlan(savedQuotation.plan));
            }
        }
    };

    const initScanInfo = () => {
        const savedDocumentScanInfo = JSON.parse(
            sessionStorage.getItem("quotation::documentScanInfo")
        );
        if (savedDocumentScanInfo) {
            dispatch(saveClientRecognitionInfo(savedDocumentScanInfo));
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Grid container justify="center" alignItems="center" className="page">
            {showHeader && (
                <QuoteTitleHeader
                    to={-1}
                    title={insurance && insurance.businessTitle}
                    riskType={riskType}
                />
            )}
            {/* {customHeader && (
                <div
                    style={{ width: "100%", marginBottom: 32 }}
                    dangerouslySetInnerHTML={{ __html: customHeader }}
                />
            )} */}
            <div style={{ width: "100%" }}>{children}</div>
            <MessageFailure />
        </Grid>
    );
};

export default QuotationContainer;
