import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Step, StepLabel, Stepper } from "@material-ui/core";
import { updateLead } from "../../../actions/leadActions";
import ClientInfoForm from '../../../components/form/layouts/clientInfoForm';
import FormPerson from "../../../components/form/formPerson";
import "../../../styles/form.css";
import { navigate, useParams } from "@reach/router";
import { GASTOS_MEDICOS } from "../../../utils/risksTypes";
import { SOLICITUD } from "../../../utils/scores";
import { toTitleCase } from "../../../utils";
import { useHandleLeadSource } from "../../../hooks/useHandleLeadSource";
import { useQuote } from "../../../hooks/useQuote";
import LayoutLoading from "../../../components/layout/layoutLoading";
import LayoutError from "../../../components/layout/layoutError";
import { saveClientRecognitionInfo } from "../../../actions/clientInfoActions";
import { getQuote } from "../../../actions/quoteActions";
import { putUpdateQuotationScreenValues } from "../../../actions/serviceActions";
import Utils from "../../../utils/utils";
import {
    DotConnector,
    DotStepIcon,
} from "../../../components/styled-stepper/styleDotStepper";
import { verifyUserStatus } from "../../../sevices/quoteService";
import { showMessage } from "../../../actions/notificationActions";
import LibraClientInfoForm from "../../../components/form/layouts/libraClientInfoForm";

const ClientInfoComponent = (props) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isStoredLink = !!urlParams.get("lead");
    const params = useParams();
    const riskType = props.riskType && props.riskType.toUpperCase();
    const dispatch = useDispatch();
    const lead = useSelector((state) => state.lead.data);
    const quoteAllPlans = useSelector((state) => state.quoteAllPlans);
    const insurances = useSelector((state) => state.insurance);
    const { document } = useSelector(
        (state) => state.clientInfo.recognitionData
    );
    const quote = useQuote(riskType);
    const { quotation, plan } = quote;
    const { quotationScreenValues } = quotation;

    const leadMail =
        quotationScreenValues && quotationScreenValues.lead
            ? quotationScreenValues.lead
            : undefined;
    let hasBlackList = false;
    if (document) {
        document.email = leadMail;
    }

    useHandleLeadSource({
        isNew: false,
        status: SOLICITUD,
        trackingStrings: ["Ingreso datos cliente"],
        riskType,
    });

    useEffect(() => {
        if (quote.quotation && quote.quotation.lead) {
            handleTracking();
        }
    }, []);

    useEffect(() => {
        if (Utils.isEmptyObject(quotation)) {
            const quotationId = params.resultQuoteId;
            dispatch(getQuote(quotationId));
        }
    }, []);

    useEffect(() => {
        if (lead && lead.status === "Emitida") {
            navigate("/quote/completed");
        }
    }, []);

    useEffect(() => {
        if (
            quoteAllPlans &&
            quoteAllPlans.quotation &&
            quoteAllPlans.quotation.quotationScreenValues &&
            quoteAllPlans.quotation.quotationScreenValues.document
        ) {
            const documentInfo =
                quoteAllPlans.quotation.quotationScreenValues.document;
            dispatch(saveClientRecognitionInfo({ document: documentInfo }));
        }

        if (
            isStoredLink &&
            quoteAllPlans &&
            quoteAllPlans.quotation &&
            quoteAllPlans.quotation.quotationScreenValues &&
            quoteAllPlans.quotation.quotationScreenValues
                .isFullfilledClientInfoForm
        ) {
            const isFullfilledForm =
                quoteAllPlans.quotation.quotationScreenValues
                    .isFullfilledClientInfoForm;
            if (isFullfilledForm) {
                goToNextPage();
            }
        }
    }, [quoteAllPlans]);

    const handleTracking = async () => {
        const { _id, tracking } = lead;

        const leadBody = {
            tracking: `${tracking},[${new Date()
                .toTimeString()
                .slice(0, 5)}] - ${toTitleCase(
                    riskType
                )}: Ingreso datos cliente`,
            status: SOLICITUD,
            url: window.location.href,
            _id: _id,
        };
        await dispatch(updateLead(leadBody));
    };

    const goToNextPage = () => {
        if (hasBlackList) return;

        const hasRC = plan.businessName.toLowerCase().includes('rc');
        console.log('Has RC?', hasRC);

        if (checkLibraPlan()) {
            if (hasRC) {
                navigate('payment');
            } else {
                navigate('inspection');
            }
        } else {
            navigate("payment");
        }
    };

    const handleDataReturn = async (data) => {
        if (riskType === GASTOS_MEDICOS) {
            const newname = data.name;
            const newsurname = data.surname;
            const allData = {
                dataList: quote.dataList,
                plan: quote.plan,
                tagsAcc: quote.tagsAcc,
                tagsCar: quote.tagsCar,
                error: quote.error,
                loading: quote.loading,
                mail: quote.mail,
                tagsContact: quote.tagsContact,
                quotation: {
                    ...quote.quotation,
                    quotationScreenValues: {
                        ...quotationScreenValues,
                        name: newname,
                        surname: newsurname,
                    },
                },
            };

            let blockedUsers = [];
            try {
                const response = await verifyUserStatus({
                    name: newname ? newname : "",
                    surname: data.surname ? data.surname : "",
                });

                blockedUsers = [...response.result.result];
            } catch (error) {
                console.log(error, "error de SISE");
            }
            const blackListLenght = blockedUsers.length;

            await dispatch(
                updateLead({
                    ...lead,
                    data: `${riskType.toUpperCase()}: ${JSON.stringify(
                        allData
                    )}`,
                    blackList: blackListLenght ? blockedUsers : [],
                })
            );

            if (
                blackListLenght &&
                !blockedUsers.every(
                    (user) =>
                        user["txt_descrip_lista"] === "PEP" ||
                        user["txt_descrip_lista"] === "LISTA GRIS"
                )
            ) {
                hasBlackList = true;
                dispatch(
                    showMessage({
                        redirect: "/",
                        variant: "alert",
                        type: "error",
                        message:
                            "No podemos procesar su solicitud en este momento. Por favor, comuniquese para mayor detalle",
                    })
                );
            }
            if (!hasBlackList) {
                const quotationId = params.resultQuoteId;
                dispatch(
                    putUpdateQuotationScreenValues({
                        quotationScreenValues: {
                            name: newname ? newname : "Anonimo",
                            surname: newsurname ? newsurname : "Anonimo",
                            isFullfilledClientInfoForm: !!data,
                            clientInfo: data
                        },
                        _id: quotationId,
                    })
                );
            }
        }

        if (riskType !== GASTOS_MEDICOS) {
            const quotationId = params.resultQuoteId;
            dispatch(
                putUpdateQuotationScreenValues({
                    quotationScreenValues: {
                        isFullfilledClientInfoForm: !!data,
                        clientInfo: data
                    },
                    _id: quotationId,
                })
            );
        }
    };

    const checkLibraPlan = () => {
        const insurance = insurances.data.find(
            (insurance) => insurance._id === quote.quotation.insuranceId
        );

        console.log('------------ INSURANCE ---------------', insurance);

        return insurance?.businessName?.toLowerCase().includes('libra');
    };

    const steps = 7;
    return (
        <>
            {quote.loading && <LayoutLoading />}
            {quote.error && <LayoutError />}
            {!quote.loading && !insurances.loading && quote.quotation && quote.quotation.products && (
                <>
                    <Container>
                        <Stepper
                            style={{ backgroundColor: "transparent" }}
                            alternativeLabel
                            activeStep={1}
                            color="primary"
                            connector={<DotConnector />}
                        >
                            {/* <QuoteProgress width="25%" /> */}
                            {Array(steps)
                                .fill("")
                                .map((label) => (
                                    <Step key={label} color="primary">
                                        <StepLabel
                                            StepIconComponent={DotStepIcon}
                                        />
                                    </Step>
                                ))}
                        </Stepper>
                        {riskType === GASTOS_MEDICOS ? (
                            <div className="cardAppContainerFormBuilder">
                                {document ? (
                                    <>
                                        {checkLibraPlan() ? (
                                            <LibraClientInfoForm
                                                plan={plan}
                                                onDataReturn={handleDataReturn}
                                                goToNextPage={goToNextPage}
                                                riskType={riskType}
                                                quotationScreenValues={
                                                    quotationScreenValues
                                                } />
                                        ) : (
                                            <ClientInfoForm
                                                onDataReturn={handleDataReturn}
                                                goToNextPage={goToNextPage}
                                                riskType={riskType}
                                                document={document}
                                                quotationScreenValues={
                                                    quotationScreenValues
                                                }
                                            />
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            <>
                                {checkLibraPlan() ? (
                                    <LibraClientInfoForm
                                        plan={plan}
                                        onDataReturn={handleDataReturn}
                                        goToNextPage={goToNextPage}
                                        riskType={riskType}
                                        quotationScreenValues={
                                            quotationScreenValues
                                        } />
                                ) : (
                                    <ClientInfoForm
                                        onDataReturn={handleDataReturn}
                                        goToNextPage={goToNextPage}
                                        riskType={riskType}
                                        quotationScreenValues={
                                            quotationScreenValues
                                        }
                                    />
                                )}
                            </>
                        )}
                    </Container>
                </>
            )}
        </>
    );
};

export default ClientInfoComponent;
